import React from "react";
import Icon from "@ant-design/icons";

const InformationSvg = () => (
  <svg width="100%" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="20" height="20" rx="10" stroke="currentColor" strokeWidth="1"/>
    <path d="M10.999 8.99988V16.9999" stroke="currentColor" strokeWidth="1"/>
    <path d="M10.999 5V7" stroke="currentColor" strokeWidth="1"/>
  </svg>
);

const InformationIcon = (props: any) => (
  <Icon component={InformationSvg} {...props} />
);

export default InformationIcon;
