import React from "react";
import Icon from "@ant-design/icons";

const CkMenuSvg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_240_619)">
      <path d="M25.8 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H25.8C26.4 7 26.8 7.4 26.8 8C26.8 8.6 26.4 9 25.8 9Z" fill="currentColor"/>
      <path d="M25.8 24.8H6C5.4 24.8 5 24.4 5 23.8C5 23.2 5.4 22.8 6 22.8H25.8C26.4 22.8 26.8 23.2 26.8 23.8C26.8 24.4 26.4 24.8 25.8 24.8Z" fill="currentColor"/>
      <path d="M25.8 16.9H6C5.4 16.9 5 16.5 5 15.9C5 15.3 5.4 14.9 6 14.9H25.8C26.4 14.9 26.8 15.3 26.8 15.9C26.8 16.5 26.4 16.9 25.8 16.9Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_240_619">
        <rect width="21.8" height="17.8" fill="white" transform="translate(5 7)"/>
      </clipPath>
    </defs>
  </svg>
);

const CkMenuIcon = (props: any) => (
  <Icon component={CkMenuSvg} {...props} />
);

export default CkMenuIcon;
