import React from "react";
import Icon from "@ant-design/icons";

const CkPhoneSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
  <g clip-path="url(#clip0_1932_38133)">
    <path d="M18.365 25.0622C15.165 25.0622 10.685 22.6622 6.52504 18.6622C0.92504 12.9022 -1.47496 6.34219 1.24504 3.62219L3.64504 1.22219C4.28504 0.582188 5.56504 0.582188 6.20504 1.22219L10.365 5.38219C10.685 5.70219 10.845 6.18219 10.845 6.66219C10.845 7.14219 10.685 7.62219 10.365 7.94219L8.60504 9.70219C9.40504 10.9822 10.525 12.2622 11.645 13.5422C12.765 14.6622 14.045 15.7822 15.325 16.5822L17.085 14.8222C17.405 14.5022 17.885 14.3422 18.365 14.3422C18.845 14.3422 19.325 14.5022 19.645 14.8222L23.805 18.9822C24.125 19.3022 24.285 19.7822 24.285 20.2622C24.285 20.7422 24.125 21.2222 23.805 21.5422L21.405 23.9422C20.765 24.7422 19.645 25.0622 18.365 25.0622ZM4.92504 2.34219C4.76504 2.34219 4.92504 2.34219 4.92504 2.34219L2.36504 4.74219C0.60504 6.50219 2.36504 12.1022 7.80504 17.3822C13.085 22.6622 18.685 24.4222 20.445 22.8222C20.605 22.6622 22.845 20.4222 22.845 20.4222V20.2622V20.1022L18.685 15.9422H18.525H18.365L16.125 18.0222C15.805 18.3422 15.325 18.3422 15.005 18.1822C13.405 17.2222 11.805 15.9422 10.365 14.6622C9.08504 13.2222 7.80504 11.6222 7.00504 10.0222C6.68504 9.70219 6.84504 9.38219 7.00504 9.06219L9.24504 6.82219V6.66219V6.50219L4.92504 2.34219C5.08504 2.34219 4.92504 2.34219 4.92504 2.34219Z" fill="currentColor"/>
  </g>
  <defs>
    <clipPath id="clip0_1932_38133">
      <rect width="24.32" height="24.32" fill="currentColor" transform="translate(0.125061 0.742188)"/>
    </clipPath>
  </defs>
</svg>
);

const CkPhoneIcon = (props: any) => (
  <Icon component={CkPhoneSvg} {...props} />
);

export default CkPhoneIcon;
