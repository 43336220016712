import React from "react";
import Icon from "@ant-design/icons";

const CkMailSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="22"
    viewBox="0 0 27 22"
    fill="none"
  >
    <g clipPath="url(#clip0_1932_36583)">
      <path
        d="M24.125 21.1034H2.68505C1.24505 21.1034 0.125046 19.9834 0.125046 18.5434V3.50336C0.125046 2.06336 1.24505 0.943359 2.68505 0.943359H24.125C25.565 0.943359 26.685 2.06336 26.685 3.50336V18.7034C26.685 19.9834 25.565 21.1034 24.125 21.1034ZM2.68505 2.54336C2.20505 2.54336 1.72505 3.02336 1.72505 3.50336V18.7034C1.72505 19.1834 2.20505 19.6634 2.68505 19.6634H24.125C24.605 19.6634 25.085 19.1834 25.085 18.7034V3.50336C25.085 3.02336 24.605 2.54336 24.125 2.54336H2.68505Z"
        fill="currentColor"
      />
      <path
        d="M13.405 15.0239C13.245 15.0239 13.085 15.0239 12.925 14.8639L0.925043 2.86391C0.605043 2.54391 0.605043 2.06391 0.925043 1.74391C1.24504 1.42391 1.72504 1.42391 2.04504 1.74391L13.565 13.2639L24.925 1.74391C25.245 1.42391 25.725 1.42391 26.045 1.74391C26.365 2.06391 26.365 2.54391 26.045 2.86391L14.045 14.8639C13.885 15.0239 13.725 15.0239 13.405 15.0239Z"
        fill="currentColor"
      />
      <path
        d="M1.40504 20.6227C1.24504 20.6227 0.92504 20.6227 0.76504 20.4627C0.44504 20.1427 0.44504 19.6627 0.76504 19.3427L9.72504 10.5427C10.045 10.2227 10.525 10.2227 10.845 10.5427C11.165 10.8627 11.165 11.3427 10.845 11.6627L1.88504 20.4627C1.88504 20.6227 1.56504 20.6227 1.40504 20.6227Z"
        fill="currentColor"
      />
      <path
        d="M25.405 20.6227C25.245 20.6227 24.925 20.6227 24.765 20.4627L16.125 11.6627C15.805 11.3427 15.805 10.8627 16.125 10.5427C16.445 10.2227 16.925 10.2227 17.245 10.5427L25.885 19.3427C26.205 19.6627 26.205 20.1427 25.885 20.4627C25.725 20.6227 25.565 20.6227 25.405 20.6227Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1932_36583">
        <rect
          width="26.56"
          height="20.16"
          fill="currentColor"
          transform="translate(0.125046 0.943359)"
        />
      </clipPath>
    </defs>
  </svg>
);

const CkMailIcon = (props: any) => <Icon component={CkMailSvg} {...props} />;

export default CkMailIcon;
